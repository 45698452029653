import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import dynamicLink from "lib/dynamicLink"
import { parseCookies, setCookie } from "nookies"
import {
  ShowUnsupportedFeatureNoticeContext,
  ShowAppDownloadModalContext,
} from "context"
import Header, { useHeader } from "components/Header"
import Footer from "components/Footer"
import Modal from "components/UI/Modal"
import Indicator from "components/UI/Indicator"
import Constants from "lib/constants"
import config from "config"
import ThemeStyle from "components/ThemeStyle"
import { event } from "lib/gtag"
import useDeviceDetect from "components/Utilities/useDeviceDetect"

const dev = process.env.NODE_ENV !== "production"

const Page = (props) => {
  const { isMobile } = useDeviceDetect()

  // Event for Google Optimize
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "optimize.activate" })
  }, [])

  const [visited, setVisited] = useState(false)

  const [showUnsupportedFeatureNotice, setShowUnsupportedFeatureNotice] =
    useState(false)
  const [showAppDownloadModal, setShowAppDownloadModal] = useState(false)

  const showUnsupportedFeatureNoticeValue = {
    showUnsupportedFeatureNotice: showUnsupportedFeatureNotice,
    setShowUnsupportedFeatureNotice: setShowUnsupportedFeatureNotice,
  }

  const showAppDownloadModalValue = {
    showAppDownloadModal: showAppDownloadModal,
    setShowAppDownloadModal: setShowAppDownloadModal,
  }

  const { toggleSiteNav, showSiteNav } = useHeader()

  const handleCloseAppDownloadModal = () => {
    setCookie(null, "adm", "1", {
      maxAge: dev ? 60 : config.modal.showInterval,
    })
    event({ action: "Close", category: "Click", label: "AppDownloadModal" })
    setShowAppDownloadModal(false)
  }

  const handleCloseUnsupportedFeatureModal = () => {
    event({
      action: "Close",
      category: "Click",
      label: "UnsupportedFeatureModal",
    })
    setShowUnsupportedFeatureNotice(false)
  }

  const handleClickDownloadApp = () => {
    event({
      action: "DownloadApp",
      category: "Click",
      label: "AppDownloadModal",
    })
  }

  const handleClickUnsupportedFeatureModal = () => {
    event({
      action: "DownloadApp",
      category: "Click",
      label: "UnsupportedFeatureModal",
    })
  }

  const handleClickOpenAppIndicator = () => {
    event({
      action: "OpenAppIndicator",
      category: "Click",
      label: "",
    })
  }

  const handleCloseOpenAppIndicator = () => {
    setCookie(null, "adm", "1", {
      maxAge: dev ? 60 : config.modal.showInterval,
    })
    event({
      action: "OpenAppIndicator",
      category: "Close",
      label: "",
    })
  }

  useEffect(() => {
    const cookies = parseCookies()
    if (cookies.adm != "1") {
      setShowAppDownloadModal(true)
    }
    if (cookies.visited != "1") {
      setCookie(null, "visited", "1", {
        maxAge: dev ? 60 : config.modal.showInterval,
      })
      setVisited(true)
    }
  })

  return (
    <ShowUnsupportedFeatureNoticeContext.Provider
      value={showUnsupportedFeatureNoticeValue}>
      <ShowAppDownloadModalContext.Provider value={showAppDownloadModalValue}>
        <main
          id={props.id}
          className={classNames(
            props.className,
            "min-h-screen min-w-full flex flex-col justify-between",
          )}>
          <ThemeStyle {...props.theme} />

          <Header toggleSiteNav={toggleSiteNav} showSiteNav={showSiteNav} />

          <div className="flex flex-col items-stretch flex-1 w-full pt-14 md:pt-16">
            {props.children}
          </div>

          <Footer className="bg-base-indented-light" />

          {showUnsupportedFeatureNotice && (
            <Modal
              title="We have an app for that"
              text="The free app is faster and smoother. Play playlists like a music app, save your favorite videos and enjoy NobodySurf at its best!"
              buttonLabel="Download NobodySurf"
              buttonHref={dynamicLink(Constants.Links.ButtonSave, props)}
              onClick={handleClickUnsupportedFeatureModal}
              onClose={handleCloseUnsupportedFeatureModal}
              overlay={true}
              theme="light"
            />
          )}
          {showAppDownloadModal &&
            (!isMobile
              ? props.showModalDesktop && (
                  <Modal
                    text={`NobodySurf is better on the app.\nFind world's best free-surfing videos.`}
                    buttonLabel="Get App"
                    buttonHref={dynamicLink(Constants.Links.TextModal, props)}
                    onClick={handleClickDownloadApp}
                    onClose={handleCloseAppDownloadModal}
                    theme="dark"
                  />
                )
              : props.showModalMobile && (
                  <Indicator
                    label={props.indicatorLabel}
                    buttonHref={dynamicLink(Constants.Links.Indicator, props)}
                    onClick={handleClickOpenAppIndicator}
                    onClose={handleCloseOpenAppIndicator}
                    appearMotion={visited}
                  />
                ))}
        </main>
      </ShowAppDownloadModalContext.Provider>
    </ShowUnsupportedFeatureNoticeContext.Provider>
  )
}

// These will need refactoring
Page.defaultProps = {
  showModalDesktop: false,
  showModalMobile: true,
}

Page.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  showModalDesktop: PropTypes.bool,
  showModalMobile: PropTypes.bool,
  indicatorLabel: PropTypes.string,
  theme: PropTypes.string,
}

export default Page
